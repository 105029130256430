<template>
  <div>
    <div id="map" :class="['google-map',{ 'visible-map': isMapVisible }]" ref="googleMap"></div>
    <div v-if="isMapVisible" class="close-btn" @click="closeMap">
      <i class="uil uil-arrow-left"></i>
    </div>
    <div v-if="isMapVisible" class="open-map-app-btn">
      <a :href="selectedPlaceLink" target="_blank">Uygulamada Aç</a>
    </div>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import {eventBus} from "@/main";

export default {
  created() {
    eventBus.$on("location", (place) => {
      this.map.setCenter(new this.google.maps.LatLng(place.coordinates));
      this.isMapVisible = true
      this.selectedPlaceLink = place.link
      const marker = this.markersByPlaceId[place.id]
      if (this.infoWindow) {
        this.infoWindow.close()
      }
      this.infoWindow = new this.google.maps.InfoWindow({
        content: place.title,
      })
      this.infoWindow.open(this.map, marker)
    })
  },
  props: {
    mapConfig: Object,
    apiKey: String,
    list: Array,
    animation: Number
  },
  data() {
    return {
      google: null,
      map: null,
      isMapVisible: false,
      selectedPlaceLink: null,
      markersByPlaceId: {},
    }
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap();
  },
  methods: {
    closeMap() {
      this.isMapVisible = false
    },
    initializeMap() {
//haritayı oluşturuyoruz
      this.map = new this.google.maps.Map(this.$refs.googleMap, {
        center: {lat: 37.21505295392125, lng: 28.363758224316076},
        zoom: 15,
      });

// burada tüm yerlerin koordinatlarını tek tek marker olarak ekliyoruz
      for (let i in this.list) {
        const place = this.list[i];
        const marker = new this.google.maps.Marker({
          position: place.coordinates,
          map: this.map,
          title: place.title,
          animation: "2"
        });
        marker.addListener('mouseover', () => {
          if (this.infoWindow) {
            this.infoWindow.close()
          }
          this.infoWindow = new this.google.maps.InfoWindow({
            content: place.title,
          })
          this.infoWindow.open(this.map, marker)
        })
        marker.addListener('mouseout', () => {
          this.infoWindow.close();
        });
        //markerların referanslarını tut
        this.markersByPlaceId[place.id] = marker
      }
    }
  }
}
</script>
<style scoped>

.google-map {
  width: 700px;
  height: 700px;
  border-radius: 30px;
  margin-left: 20px;
  margin-top: 50px;
  border: 2px solid white;
  outline: none;
}

.close-btn {
  display: none;
}

.open-map-app-btn{
  display: none;
}
@media screen and (max-width: 960px) {
  .google-map {
    display: none;
  }

  .google-map.visible-map {
    display: block;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .close-btn {
    position: fixed;
    left: 1rem;
    bottom: 1.5rem;
    background-color: #60d3ff;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    text-align: center;
    line-height: 3rem;
    color: white;
    font-size: 2em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: block;
  }

  .open-map-app-btn {
    position: fixed;
    background-color: #60d3ff;
    left: 8rem;
    bottom: 1.5rem;
    padding: 10px;
    border-radius: 10px;
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .open-map-app-btn a {
    color: white;
    font-weight: bold;
  }
}

</style>