<template>
  <div class="auth-section">
    <form @click.prevent="onSubmit">
      <div class="auth-container" :class="{'giris' : isUser, 'kayit' : !isUser}">
        <label for="email">E-mail</label>
        <input type="text" placeholder="E-mail" id="email" class="" v-model="user.email">
        <label for="password">Password</label>
        <input type="password" placeholder="Şifre" id="password" class="" v-model="user.password">
        <button
            type="submit"
            :class="{'girisYap' : isUser, 'kayitOl': !isUser} "
        >
          {{ isUser ? 'Giriş Yap' : 'Kayıt Ol' }}
        </button>
        <a href="#" @click="isUser=!isUser" class="uyelik">
          {{ isUser ? 'Üye Değilim' : 'Üyeliğim Var' }}
        </a>
      </div>
    </form>
  </div>
</template>

<script>
import {mapMutations} from "vuex"


export default {
  name: "Auth",
  data() {
    return {
      isUser: false,
      user: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    ...mapMutations([
      'setToken',
    ]),
    onSubmit() {
      this.$store.dispatch("login", {...this.user, isUser: this.isUser})
          .then(response => {
            console.log(response)
            this.$router.push("/")
          })
    }
  }
}
</script>

<style scoped>
.auth-section {
  display: flex;
  place-items: center;
}

input {
  border-radius: 10px;
  padding: 10px;
  outline: none;
  border: none;
  width: 300px;
  margin-top: 10px;
}

button {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #67eaff;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}

.uyelik {
  text-decoration: underline;
  color: #303030;
  margin-top: 10px;
}

.giris {
  border: 2px solid blue;
}

.kayit {
  border: 2px solid green;

}

.girisYap {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: blue;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}

.kayitOl {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: green;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}

.auth-container {
  width: 400px;
  height: 400px;
  background-color: #d2d2d2;
  border-radius: 10px;
  display: flex;
  place-items: center;
  flex-direction: column;
  justify-content: center;
}

</style>