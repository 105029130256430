<template>
  <div class="general-content-section">

    <div>
      <app-card-list v-if="list.length > 0" :list="list"></app-card-list>

    </div>
    <div>
      <app-map-view v-if="list.length > 0" :list="list" apiKey="AIzaSyBovTZ_n1U74ySbzqC1uiVeNedGGWHjn2s"></app-map-view>
    </div>

  </div>
</template>

<script>
import MapView from "@/components/MapView";
import CardList from "@/components/CardList";
import {eventBus} from "@/main";

export default {
  name: "PlaceListContainer",
  components: {
    appMapView: MapView,
    appCardList: CardList,
  },
  created() {
    this.getUsers();
    eventBus.$emit("sendList", this.list)
  },
  data() {
    return {
      list: []
    }
  },
  methods: {
    getUsers() {
      console.log(1)
      this.$http.get("https://disci-listesi-default-rtdb.firebaseio.com/placeList.json")
          .then((response) => {
            console.log(2)
            let data = response.data;
            for (let key in data) {
              this.list.push(data[key])
            }
          }).catch(() => {
        console.log(3)
      })
    }
  }
}
</script>

<style scoped>

.general-content-section{
  display: flex;
  place-items: center;
}

@media screen and (max-width: 960px){

}

</style>