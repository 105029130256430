<template>
  <div>
      <app-place-list-container></app-place-list-container>

  </div>
</template>

<script>
import PlaceListContainer from "@/components/PlaceListContainer";

export default {
  name: "HomePage",
  components: {
    appPlaceListContainer: PlaceListContainer,

  }
}
</script>

<style scoped>



</style>