<template>
  <div>
    <div class="inputContainer">
      <label for="title">Başlık</label>
      <input type="text" placeholder="" id="title" v-model="person.title"><br>
      <label for="name">Hekim Ad</label>
      <input type="text" placeholder="" id="name" v-model="person.doctors[0].name"><br>
      <label for="surname">Hekim Soyad</label>
      <input type="text" placeholder="" id="surname" v-model="person.doctors[0].surname"><br>
      <label for="addres">Adres</label>
      <input type="text" placeholder="" id="addres" v-model="person.address"><br>
      <label for="Phone">Telefon</label>
      <input type="text" placeholder="" id="Phone" v-model="person.phone"><br>
      <label for="coordinatesLAT">Korodinat LAT</label>
      <input type="text" placeholder="" id="coordinatesLAT" v-model="person.coordinates.lat"><br>
      <label for="coordinatesLNG">Koordinat LNG</label>
      <input type="text" placeholder="" id="coordinatesLNG" v-model="person.coordinates.lng"><br>
      <label for="link">Link</label>
      <input type="text" placeholder="" id="link" v-model="person.link"><br>
      <label for="id">ID</label>
      <input type="text" placeholder="" id="id" v-model="person.id"><br>
      <button class="sendInfo" @click="sendInfo">Gönder</button>
      <router-link to="/" tag="button" class="sendInfo">Home Page</router-link>
    </div>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "SetNewUser",
  props: ["person"],
  methods: {
    sendInfo() {
      if (this.person.title !== null) {
        eventBus.$emit("personAdd", this.person);
        this.person = {
          person: {
            title: null,
            doctors: [
              {"name": null},
              {"surname": null}
            ],
            address: null,
            phone: null,
            coordinates: [
              {"lat": null},
              {"lng": null},
            ],
            link: null,
            id: null
          }
        }
      } else {
        alert("Lütfen önce bilgileri doldurunuz")
      }
    },
  }
}
</script>

<style scoped>

input {
  border-radius: 10px;
  padding: 10px;
  outline: none;
  border: none;
  width: 300px;
  margin-top: 10px;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.sendInfo {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #01cbe9;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}


</style>