<template>
  <div class="general-section">

    <div class="tooth-icon-container">
      <svg class="tooth-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 172 172"
           style=" fill:#000000; ">
        <defs>
          <linearGradient x1="71.79208" y1="19.25325" x2="114.88525" y2="146.7805" gradientUnits="userSpaceOnUse"
                          id="color-1_rX28Hj1fGQoh_gr1">
            <stop offset="0" stop-color="#ffffff"></stop>
            <stop offset="0.223" stop-color="#ffffff"></stop>
            <stop offset="0.53" stop-color="#ffffff"></stop>
            <stop offset="0.885" stop-color="#ffffff"></stop>
            <stop offset="1" stop-color="#ffffff"></stop>
          </linearGradient>
        </defs>
        <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter"
           stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-size="none"
           style="mix-blend-mode: normal">
          <path d="M0,172v-172h172v172z" fill="none"></path>
          <g fill="url(#color-1_rX28Hj1fGQoh_gr1)">
            <path
                d="M111.08333,21.5c-14.33333,0 -20.5325,7.16667 -25.08333,7.16667c-4.55083,0 -10.75,-7.16667 -25.08333,-7.16667c-28.66667,0 -39.41667,19.35 -39.41667,38.7c0,25.56708 14.05383,35.26 17.91667,54.46667c1.8705,9.29517 4.47558,35.83333 17.91667,35.83333c21.5,0 11.94683,-46.58333 28.66667,-46.58333c16.71983,0 7.16667,46.58333 28.66667,46.58333c13.44108,0 16.04617,-26.53817 17.91667,-35.83333c3.86283,-19.20667 17.91667,-28.89958 17.91667,-54.46667c0,-19.35 -10.75,-38.7 -39.41667,-38.7z"></path>
          </g>
        </g>
      </svg>


      <ul class="logoutUl" :class="logoutClass">
        <li class="">
          <a @click.prevent="logout" class="logout" href="#">Çıkış Yap</a>
        </li>
      </ul>

    </div>
    <hr class="head-hr-container">

    <div id="app">
      <router-view></router-view>
      <!-- <app-home-page></app-home-page> -->
    </div>

  </div>
</template>

<script>
//import HomePage from "@/components/HomePage";


export default {
  name: 'App',
  created() {
    this.$store.dispatch("initAuth")
  },
  methods: {
    logout() {
      this.$store.dispatch("logout")
      this.$router.push("/auth")
    }
  },
  computed: {
    logoutClass() {
      return {
        'd-none': !this.$store.getters.isAuthenticated
      }
    }
  },
  components: {
    // appHomePage: HomePage
  }
}
</script>

<style>

* {
  box-sizing: border-box;
}

.logout {
  border: 1px solid #9f9f9f;
  border-radius: 10px;
  padding: 10px;
  color: white;
  background-color: #aeaeae;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.logoutUl {
  list-style-type: none;
  margin-top: 20px;
  margin-right: 20px;
}

.logoutUl a {
  text-decoration: none;
}

.head-hr-container {
  height: 1px;
  border-width: 0;
  color: white;
  background-color: white;
  width: 98%;
}

::-webkit-scrollbar {
  width: 15px;
  background: #eaeaea;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bfe8ff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #bcbcbc;
  border-radius: 5px;
}

body {
  background-color: #abf2ff;
  font-family: 'Lato', sans-serif;
}

#app {
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
}

.tooth-icon-container {
  display: flex;
  justify-content: center;
}


.tooth-icon {
  width: 48px;
  height: 48px;
  flex: 1;
}

@media screen and (max-width: 960px) {
  .tooth-icon {
    width: 36px;
    height: 36px;
  }
}

</style>
