<template>
  <div class="adminViewSection">

    <app-set-new-user :person="person"></app-set-new-user>

    <div>
      <app-persons></app-persons>
    </div>

  </div>
</template>

<script>
import Persons from "@/components/Persons";
import SetNewUser from "@/components/SetNewUser";


export default {
  name: "AdminPage",
  created() {
  },
  components: {
    appPersons: Persons,
    appSetNewUser: SetNewUser,
  },
  data() {
    return {
      person: {
        title: null,
        doctors: [
          {"name": null},
          {"surname": null}
        ],
        address: null,
        phone: null,
        coordinates: [
          {"lat": null},
          {"lng": null}
        ],
        link: null,
        id: null
      }
    }
  },

}
</script>

<style scoped>

.adminViewSection {
  display: flex;
  justify-content: space-between;
}

</style>