<template>
  <div>
    <h3> Eklenen Kişi Listesi </h3>

    <div class="personView" :class="{ 'personViewOVerflow': personList.length > 0 }">
      <app-person v-for="item in personList" :key="item">
        <div class="infoCard">
          <small> <strong>Başlık : </strong> {{ item.title }} </small>
          <br>
          <small><strong>Hekim : </strong> {{ item.doctors[0].name }} {{ item.doctors[0].surname }} </small>
          <br>
          <small><strong>Adres : </strong> {{ item.address }} </small>
          <br>
          <small><strong>Telefon : </strong> {{ item.phone }} </small>
          <br>
          <small><strong>koordinat : </strong> {{ item.coordinates.lat }} , {{ item.coordinates.lng }} </small>
          <br>
          <small><strong>Link : </strong> {{item.link}} </small>
          <br>
          <small><strong>ID : </strong> {{item.id}} </small>
          <br>
        </div>
      </app-person>
    </div>

  </div>
</template>

<script>
import Person from "@/components/Person";
import {eventBus} from "@/main";


export default {
  name: "Person",
  components: {
    appPerson: Person
  },
  props: [],
  data() {
    return {
      personList: []
    }
  },
  created() {

    this.getUsers()

    eventBus.$on("personAdd", (person) => {
      this.personList.push(person)
    })

    eventBus.$on("sendList", (list) => {
      this.personList = list
    })

  },
  methods: {
    getUsers() {
      this.$http.get("https://disci-listesi-default-rtdb.firebaseio.com/placeList.json")
          .then((response) => {
            let data = response.data;
            for (let key in data) {
              this.personList.push(data[key])
            }
          }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>

h3 {
  text-align: center;
}

.personViewOVerflow {
  overflow: scroll;
  overflow-x: hidden;
}

.personView {
  width: 500px;
  height: 600px;
  margin-left: 50px;
}

.infoCard {
  background-color: #d4d4d4;
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>