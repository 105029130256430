import Vue from "vue"
import Vuex from "vuex"
import axios from "axios";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        token: "",
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        clearToken(state) {
            state.token = ""
        }
    },
    actions: {
        initAuth({commit}) {
            let token = localStorage.getItem("token")
            if (token) {
                commit("setToken", token)
                this.$router.push("/")
            } else {
                this.$router.push("/auth")
                return false
            }
        },
        login({commit}, authdata) {
            let authLink = "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key="
            if (authdata.isUser) {
                authLink = "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key="
            }
            return axios.post(authLink + "AIzaSyDo95AWExTzqeaQyO6ql9otJw2gu6QGVYA",
                {email: authdata.email, password: authdata.password, retrunSecureToken: true}
            ).then(response => {
                console.log(response)
                commit("setToken", response.data.idToken)
                localStorage.setItem("token", response.data.idToken)
            })
        },
        logout({commit}) {
            commit("clearToken")
            localStorage.removeItem("token")
        }
    },
    getters: {
        isAuthenticated(state) {
            return state.token !== ""
        }
    }
})

export default store