import HomePage from "@/components/HomePage";
import AdminPage from "@/components/AdminPage";
import Auth from "@/components/Auth";
import store from "./store"

export const routes = [
    {
        path: '/',
        component: HomePage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                next()
            } else {
                next("/auth")
            }
        }
    },
    {
        path: '/adminpage',
        component: AdminPage,
        beforeEnter(to, from, next) {
            if (store.getters.isAuthenticated) {
                next()
            } else {
                next("/auth")
            }
        }
    },
    {path: '/auth', component: Auth,}
]

