<template>
  <div class="general-left-container">
    <div class="left-container-head">
      <h2>Klinik Ve Hekim Listesi</h2>
      <span> {{ list.length }} Sonuç</span>
    </div>

    <div class="card-content-overflow">
      <app-place-card v-for="item in list" :key="item.id" :place="item"></app-place-card>
    </div>

  </div>
</template>

<script>
import PlaceCard from "@/components/PlaceCard";

export default {
  name: "CardList",
  components: {
    appPlaceCard: PlaceCard
  },
  props: ["list"]
}
</script>

<style scoped>

.left-container-head {
  display: flex;
  place-items: center;
}

.left-container-head h2 {
  color: #4b4b4b;
  font-weight: bold;
}

.left-container-head span {
  color: #7f8fa6;
  margin: 5px 0 0 10px;
  font-weight: normal;
}

.card-content-overflow {
  height: 650px;
  overflow: auto;
  border-radius: 10px;
  padding: 1rem 0 0 1rem;
}

@media screen and (max-width: 960px) {

  .card-content-overflow {
    overflow: hidden;
    max-width: 650px;
    height: auto;
    padding: 5px;
  }

  .left-container-head h2 {
    color: #3c3c3c;
    font-weight: bold;
    font-size: 18px;
  }

  .left-container-head span {
    color: #7e7e7e;
    margin: 5px 0 0 10px;
    font-weight: normal;
    font-size: 12px;
  }
}


</style>