<template>
  <div class="card-section">

        <div class="card-content-container" @mouseover="sendLocation">
          <span class="card-content-img">
            <img :src="place.img">
          </span>
          <div class="card-content">
            <div class="card-content-title">
                <h2>{{place.title}}</h2>
            </div>
            <div class="span-line-heigth">
              <span v-if="place.doctors.length > 1"><svg class="icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"  font-size="none"  style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#cccccc"><path d="M86,0c-26.49875,0 -31.30937,7.47125 -32.1425,9.7825c-0.09406,0.25531 -0.18812,0.59125 -0.215,0.86l-3.9775,35.045c5.2675,-2.29781 12.48344,-2.00219 21.8225,-1.505c4.34031,0.22844 9.25844,0.5375 14.5125,0.5375c5.24063,0 10.17219,-0.30906 14.5125,-0.5375c9.32563,-0.49719 16.555,-0.79281 21.8225,1.505l-3.9775,-35.045c-0.02687,-0.26875 -0.12094,-0.60469 -0.215,-0.86c-0.83312,-2.31125 -5.65719,-9.7825 -32.1425,-9.7825zM82.56,13.76h6.88v10.32h10.32v6.88h-10.32v10.32h-6.88v-10.32h-10.32v-6.88h10.32zM64.1775,50.74c-6.45,-0.18812 -11.395,0.22844 -13.76,2.4725c-1.54531,1.47813 -2.2575,4.11188 -2.2575,8.0625c0,20.86844 16.97156,37.84 37.84,37.84c20.86844,0 37.84,-16.97156 37.84,-37.84c0,-3.95062 -0.71219,-6.58437 -2.2575,-8.0625c-3.14437,-2.98312 -10.95156,-2.66062 -20.7475,-2.15c-4.42094,0.22844 -9.40625,0.5375 -14.835,0.5375c-5.42875,0 -10.41406,-0.30906 -14.835,-0.5375c-2.44562,-0.13437 -4.8375,-0.25531 -6.9875,-0.3225zM86,102.555c-7.71312,0 -15.38594,0.69875 -22.6825,2.0425l22.6825,55.04l23.005,-54.9325c-7.4175,-1.3975 -15.1575,-2.15 -23.005,-2.15zM56.545,106.1025c-27.99031,6.79938 -49.665,22.37344 -49.665,38.7v27.1975h79.12c-1.38406,0 -2.70094,-0.86 -3.225,-2.15zM86,172h79.12v-27.1975c0,-16.24594 -21.43281,-31.84687 -49.235,-38.7l-26.66,63.7475c-0.5375,1.27656 -1.84094,2.15 -3.225,2.15z"></path></g></g></svg></span>
              <span v-else><svg class="icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"  font-size="none"  style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#cccccc"><path d="M86,0c-26.49875,0 -31.30937,7.47125 -32.1425,9.7825c-0.09406,0.25531 -0.18812,0.59125 -0.215,0.86l-3.9775,35.045c5.2675,-2.29781 12.48344,-2.00219 21.8225,-1.505c4.34031,0.22844 9.25844,0.5375 14.5125,0.5375c5.24063,0 10.17219,-0.30906 14.5125,-0.5375c9.32563,-0.49719 16.555,-0.79281 21.8225,1.505l-3.9775,-35.045c-0.02687,-0.26875 -0.12094,-0.60469 -0.215,-0.86c-0.83312,-2.31125 -5.65719,-9.7825 -32.1425,-9.7825zM82.56,13.76h6.88v10.32h10.32v6.88h-10.32v10.32h-6.88v-10.32h-10.32v-6.88h10.32zM64.1775,50.74c-6.45,-0.18812 -11.395,0.22844 -13.76,2.4725c-1.54531,1.47813 -2.2575,4.11188 -2.2575,8.0625c0,20.86844 16.97156,37.84 37.84,37.84c20.86844,0 37.84,-16.97156 37.84,-37.84c0,-3.95062 -0.71219,-6.58437 -2.2575,-8.0625c-3.14437,-2.98312 -10.95156,-2.66062 -20.7475,-2.15c-4.42094,0.22844 -9.40625,0.5375 -14.835,0.5375c-5.42875,0 -10.41406,-0.30906 -14.835,-0.5375c-2.44562,-0.13437 -4.8375,-0.25531 -6.9875,-0.3225zM86,102.555c-7.71312,0 -15.38594,0.69875 -22.6825,2.0425l22.6825,55.04l23.005,-54.9325c-7.4175,-1.3975 -15.1575,-2.15 -23.005,-2.15zM56.545,106.1025c-27.99031,6.79938 -49.665,22.37344 -49.665,38.7v27.1975h79.12c-1.38406,0 -2.70094,-0.86 -3.225,-2.15zM86,172h79.12v-27.1975c0,-16.24594 -21.43281,-31.84687 -49.235,-38.7l-26.66,63.7475c-0.5375,1.27656 -1.84094,2.15 -3.225,2.15z"></path></g></g></svg> </span>
              <span v-for="doctor in place.doctors" :key="doctor.name" class="names">
              {{doctor.name}} {{doctor.surname}}
              </span><br>
              <span class="address"><svg class="icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"  font-size="none"  style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#cccccc"><path d="M86,3.44c-30.34187,0 -55.04,24.69813 -55.04,55.04c0,48.54969 50.32344,106.45188 52.46,108.8975c0.65844,0.7525 1.58563,1.1825 2.58,1.1825c1.06156,-0.06719 1.92156,-0.43 2.58,-1.1825c2.13656,-2.48594 52.46,-61.3825 52.46,-108.8975c0,-30.34187 -24.69812,-55.04 -55.04,-55.04zM86,41.28c11.395,0 20.64,9.245 20.64,20.64c0,11.395 -9.245,20.64 -20.64,20.64c-11.395,0 -20.64,-9.245 -20.64,-20.64c0,-11.395 9.245,-20.64 20.64,-20.64z"></path></g></g></svg> {{place.address}}</span><br>
              <span class="phone"><svg class="icons" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 172 172" style=" fill:#000000;"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none"  font-size="none"  style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><g fill="#cccccc"><path d="M82.11567,23.73958l6.15975,14.53042c2.68392,6.33175 1.19683,13.77792 -3.68367,18.40758l-11.50967,10.922c-1.29717,1.23267 -1.97083,3.02075 -1.68058,4.78375c1.11083,6.74025 3.57258,13.38733 7.38525,19.94483c3.8055,6.54317 8.43875,12.11167 13.90333,16.70192c1.44767,1.21475 3.45792,1.53008 5.24958,0.93167l13.42675,-4.47917c6.18125,-2.06042 12.91075,0.30817 16.70192,5.87667l8.83292,12.97525c4.4075,6.47508 3.61558,15.40475 -1.85258,20.89442l-5.86233,5.88383c-5.83367,5.85517 -14.16492,7.98008 -21.87267,5.57567c-18.19617,-5.676 -34.92317,-22.52483 -50.18817,-50.55008c-15.2865,-28.06467 -20.67942,-51.8795 -16.18233,-71.43733c1.892,-8.23092 7.75075,-14.78483 15.40117,-17.22867l7.71492,-2.46533c7.23833,-2.30408 14.96042,1.42975 18.05642,8.73258z"></path></g></g></svg><a class="phone" :href="`tel:${place.phone}`">{{place.phone}}</a> </span><br>
              <button  class="location-button"> Konuma Git </button>
            </div>
          </div>
      </div>

  </div>
</template>
<script>
import {eventBus} from "../main"

export default {
  created() {

    },
  name: "PlaceCard",
  data(){
    return{
      onOff: false,
    }
  },
  props:["place"],
  methods:{
    sendLocation(){
      eventBus.$emit("location", this.place)
    },
  }
}
</script>

<style scoped>

.card-content-container{
  display: flex;
  width: auto;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 700px;
  margin-right: 20px;
  box-shadow: 4px 2px 2px rgba(140, 153, 151, 0.3);
  background-color: white;
  transition: box-shadow .1s ease-in-out;
  cursor: pointer;
}

.card-content-container:hover{
  box-shadow: 0 0 0 2px #c6cdcd;
}

.card-content-img{
  display: grid;
  place-items: center;
  padding: 10px;
}

.card-content-img img{
  object-fit:cover;
  width: 165px;
  height: 165px;
  border-radius: 10px;
}

.card-content {
  margin-left: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.span-line-heigth{
  max-width: 500px;
}

.card-content-title h2:hover{
  color: #596275;
}

.icons{
  margin-right: 0.2rem;
}

.card-content h2{
  margin: 0;
  display: inline-block;
  font-size: 23px;
  color: #303952;
  font-weight: bolder;
}

.names{
  color: #303952;
  font-weight: bold;
}

.address{
  color: #7f8fa6;
}

.phone{
  color: #6fafde;
}

.location-button{
  /*background-color: #6cdcf6;*/
  background-color: white;
  width: 100px;
  padding: 5px;
  color: #6cdcf6;
  font-weight: bold;
  border-radius: 7px;
  border: 2px solid #6cdcf6;
  cursor: pointer;
  outline: none;
  display: none;
}

.location-button:hover{
  color: white;
  background-color: #a7f0ff;
  transition: background-color .2s ease-in-out;
}

.location-button:active{
  border: 2px solid whitesmoke;
}

@media screen and (max-width: 960px){
  .card-content-container{
    display: flex;
    width: auto;
    height: auto;
    min-height: 145px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-right: 0;
    box-shadow: 4px 2px 2px rgba(140, 153, 151, 0.3);
    background-color: white;
    cursor: auto;
  }

  .icons{
    width: 15px;
    height: 15px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    line-height: 16px;
  }

  .span-line-heigth{
    width: auto;
  }

  .card-content-img{
    padding: 10px;
  }

  .card-content-img img{
    object-fit:cover;
    width: 100px;
    height: 120px;
    border-radius: 10px;
  }

  .card-content h2{
    margin: 0;
    display: inline-block;
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bolder;
  }

  .names{
    color: #34495e;
    font-size: 14px;
    font-weight: bold;
  }

  .address{
    color: #7f8fa6;
    font-size: 13px;
  }

  .phone{
    color: #6fafde;
    font-size: 14px;
  }

  .location-button{
    margin-top: 5px;
    background-color: white;
    color: #6cdcf6;
    border: 2px solid #6cdcf6;
    width: 100px;
    padding: 3px;
    font-weight: bold;
    border-radius: 7px;
    cursor: pointer;
    outline: none;
    display: block;
  }
}

@media screen and (max-width: 500px){
  .card-content-img img{
    object-fit:cover;
    width: 95px;
    height: 110px;
    border-radius: 10px;
  }

  .card-content h2{
    margin: 0;
    display: inline-block;
    font-size: 13px;
    color: #3c3c3c;
    font-weight: bolder;
  }

  .names{
    color: #34495e;
    font-size: 12px;
    font-weight: bold;
  }

  .address{
    color: #7f8fa6;
    font-size: 11px;
  }

  .phone{
    color: #6fafde;
    font-size: 11px;
  }
}


</style>